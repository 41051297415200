import { NextPage } from 'next';
import Router from 'next/router';
import React from 'react';
import styled from 'styled-components';
import { Button } from 'src/components/atoms/Button';
import { centeredFlexLine } from 'styles/mixin';
import { colorOptions } from 'styles/styles';

const StyledNotFound = styled.div`
  height: 100vh;
  ${centeredFlexLine}
  text-align: center;
  .main-text {
    margin: 1rem 0 0 0;
    font-family: 'Noto_Sans_JP_Bold', sans-serif;
    width: 90%;
  }

  .sub-text {
    margin: 0.5rem 0 1rem 0;
    font-size: 0.8rem;
    width: 60%;
  }
`;

const Custom404: NextPage = () => {
  return (
    <StyledNotFound>
      <img
        className="file_broken"
        src="/file_broken.png"
        alt="file_broken"
        width="40px"
        height="40px"
      />
      <p className="main-text">お探しのページは見つかりませんでした</p>
      <p className="sub-text">URLに問題があるか、該当のページが削除された可能性があります。</p>
      <Button
        fontSize="0.8rem"
        padding="0.8rem 3rem"
        margin="0.3rem"
        themeColor={colorOptions.BLACK}
        onClick={() => Router.push('/')}
      >
        トップへ戻る
      </Button>
    </StyledNotFound>
  );
};

export default Custom404;
